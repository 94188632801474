@import '../../scss/base.module';

$svg-size-base: 12px;
$svg-size-big: 20px;

$svg-wrapper-size-base: 35px;
$svg-wrapper-size-big: 55px;

.gallery-arrow {
  align-items: center;
  color: black;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }

  .pointer {
    pointer-events: all;
    outline: none;

    &--small {
      padding: $spacing-xs $spacing-xs;
      & > svg {
        width: $svg-size-base;
      }
    }

    &--big {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($interaction-dark, 0.4);
      border-radius: 50%;
      margin: $spacing-xs $spacing-l;

      height: $svg-wrapper-size-base;
      width: $svg-wrapper-size-base;

      @include from-tablet {
        height: $svg-wrapper-size-big;
        width: $svg-wrapper-size-big;
      }

      & > svg {
        width: $svg-size-base;

        @include from-tablet {
          width: $svg-size-big;
        }
      }
    }

    &--with-background {
      padding: $spacing-xs $spacing-xs;
      & > svg {
        width: 35px !important;
      }
    }

  }

  .pointer-shadow {
    filter: drop-shadow(0px 2px 4px rgba($interaction-dark, 0.4));
  }
}
