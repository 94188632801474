@import '../../../scss/base.module';

.home-card-label-list {
  display: flex;
  min-width: 84px;
  align-items: center;
  max-width: 75%;
  flex-wrap: wrap;

  & > * {
    margin-bottom: $spacing-xxs;
  }

  & > *:not(:last-child) {
    margin-right: $spacing-xxs;
  }
}
