@import '@spotahome/marketplace-common/src/scss/base';

.search-title {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  align-items: initial;

  @include from-tablet {
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
  }
  @include from-laptop {
    flex-wrap: wrap;
  }
  @include from-desktop {
    flex-wrap: wrap;
  }
  @include from-wide {
    flex-wrap: nowrap;
    align-items: center;
  }

  &__title {
    flex-shrink: 0;
    margin: 0 $spacing-xs 0 0;
    line-height: $line-height-xs;
    @include font-regular;
    @include font-xs;

    &results {
      @include font-bold;
    }
  }

  &__clear {
    @include font-xs;
  }

  &__summary {
    display: flex;
    width: 100%;
    margin-top: $spacing-s;

    @include from-tablet {
      margin-top: auto;
    }

    @include from-laptop {
      margin-top: $spacing-s;
    }

    @include from-wide {
      margin-top: 0;
    }
  }
}
