@import '../scss/base';

.header-menu-wrapper {
  /*
  * note: this overrides the z-index from the UI Library.
  * https://github.com/spotahome/react-ui-library/blob/4f274715511f34268921ec87aeeebb1c2afde210/src/components/HeaderContainer/Header/Header.scss#L9
  * marketplace-pages is the only BFF with specific behaviours that layer above the Header
  */
  z-index: $SPOTAHOME_HEADER_INDEX !important;
  position: relative;
}
