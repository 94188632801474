@import '../base';

.pac {
  &-container {
    z-index: 99999;

    span {
      font-size: 0.8rem;
    }

    @include until-phablet {
      width: calc(100% - 34px) !important;
      left: 17px !important;
      margin-top: $spacing-xxs;
    }
  }

  &-item {
    padding: 0 $spacing-s 0 $spacing-s;
    line-height: $spacing-xl;

    @include until-tablet {
      padding: 0 $spacing-s 0 $spacing-s;
    }

    @include until-phablet {
      padding: 1rem;
    }
  }

  &-matched {
    color: $primary;
  }

  &-icon {
    display: none;
  }
}
