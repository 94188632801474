@import '@spotahome/marketplace-common/src/scss/base';

.icon-pre-filter-item {
  &__checkbox {
    display: none;

    &:checked + .icon-pre-filter-item__label {
      border-color: $medium;
    }
  }

  &__icon {
    border-radius: 100%;
    background-color: $tertiary;
    padding: $spacing-xs;
    height: 40px;
    width: 40px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: $spacing-xs;
    border: 1px solid transparent;
    transition: border-color .1s ease-in-out;
    border-radius: 16px;

    @media (hover: hover) {
      &:hover {
        border-color: $medium;
      }
    }

    @include from-tablet {
      width: 100%;
      height: 100%;
      background-size: auto;
    }

    @include from-laptop {
      justify-content: center;
    }
  }

  &__label-text {
    @include font-s;
    @include font-bold;
    line-height: $spacing-m;
    color: black;

    @include from-tablet {
      @include font-m;
      @include font-bold;
      position: static;
    }
  }
}
