@import '../../scss/base.module';

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover {
    .tooltip__text {
      display: block;
      opacity: 1;
    }
  }

  &__text {
    @include font-xs;
    @include font-bold;

    --arrow-placement: #{$spacing-xs};

    background-color: var(--tooltip-label-color);
    line-height: 1.5;
    display: none;
    min-width: 170px;
    color: white;
    padding: $spacing-xs $spacing-m;
    border-radius: $spacing-xxs;
    text-transform: none;
    word-break: break-word;
    white-space: pre-line;

    &--rebranded {
      --arrow-placement: #{$spacing-m};
      border-radius: $spacing-m;
      color: black;
      box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.28);
    }

    /* Position the tooltip text */
    position: absolute;
    left: -20px;
    z-index: $tooltip-z-index + 1;

    /* Tooltip arrow */
    &:after {
      content: '';
      position: absolute;
      left: 20%;
      margin-left: -$spacing-xs;
      border-width: $spacing-xxs;
      border-style: solid;
      border-color: transparent;
    }

    /* Set tooltip position above label */
    &--top {
      bottom: calc(100% + #{$spacing-xxs});

      &:after {
        border-top-color: var(--tooltip-label-color);
        top: 100%;
      }
    }

    /* Set tooltip position below label */
    &--bottom {
      top: calc(100% + #{$spacing-xxs});

      &:after {
        border-bottom-color: var(--tooltip-label-color);
        bottom: 100%;
      }
    }

    /* Set tooltip position right of the label */
    &--right {
      left: calc(100% + #{$spacing-xxs});

      &:after {
        border-color: transparent;
        border-right-color: var(--tooltip-label-color);
        left: 0;
        top: calc(50% - #{$spacing-xxs}); // In the middle with the height of the arrow
      }

      &.tooltip__text--bottom {
        top: -$spacing-xxs;

        &:after {
          border-bottom-color: transparent;
          top: var(--arrow-placement); // Align arrow to the top of the tooltip
        }
      }

      &.tooltip__text--top {
        bottom: -$spacing-xxs;

        &:after {
          border-top-color: transparent;
          top: initial;
          bottom: var(--arrow-placement); // Align arrow to the bottom of the tooltip
        }
      }
    }

    /* Set tooltip position left of the label */
    &--left {
      right: calc(100% + #{$spacing-xxs});
      left: unset;

      &:after {
        border-color: transparent;
        border-left-color: var(--tooltip-label-color);
        left: 100%;
        top: calc(50% - #{$spacing-xxs}); // In the middle with the height of the arrow
        margin-left: 0;
      }

      &.tooltip__text--bottom {
        top: -$spacing-xxs;

        &:after {
          border-bottom-color: transparent;
          top: var(--arrow-placement); // Align arrow to the top of the tooltip
        }
      }

      &.tooltip__text--top {
        bottom: -$spacing-xxs;

        &:after {
          border-top-color: transparent;
          top: initial;
          bottom: var(--arrow-placement); // Align arrow to the bottom of the tooltip
        }
      }
    }
  }
}
