@import '../../../scss/base.module';

.wrapper {
  @include font-xxs;
  @include font-bold;

  &--default {
    color: $interaction-dark;
  }

  &--light-black {
    color: $interaction-dark;
  }

  &--interaction {
    color: $interaction;
  }

  &--dark {
    color: $interaction-dark;
  }

  &--inline {
    display: inline;
  }

  & .price,
  & .currency {
    @include font-bold;
    @include font-s;

    &--bigger-font {
      @include font-m;
    }
  }

  & .price-monthly {
    white-space: nowrap;

    &--rebranding-style {
      @include font-s;
      @include font-light
    }

    &--bigger-font {
      @include font-m;
    }
  }
}
