@import '../../scss/base';

.unavailable-listing {
  &__container {
    @include font-regular;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacing-xxl $spacing-l;
    color: $dark;
  }

  &__close {
    position: absolute;
    top: $spacing-l;
    right: $spacing-l;
  }

  &__title {
    @include font-bold;
    font-size: 35px;
    line-height: 1;
    text-align: center;
    margin-bottom: $spacing-s;
    @include from-tablet {
      margin-bottom: $spacing-l;
    }
    @include from-desktop {
      margin-bottom: $spacing-xxl;
    }
  }

  &__image {
    max-width: 320px;
  }

  &__description {
    text-align: center;
    margin: $spacing-l 0;
    max-width: 300px;
  }
}
