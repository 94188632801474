@import '../../../../scss/base.module';

$spacing: $spacing-m;

.horizontal-home-card {
  &__image-container {
    position: relative;
    overflow: hidden;
    line-height: 0;
    flex: 0 0 100px;

    @include from-tablet {
      flex: 0 0 150px;
    }
  }
  &__image {
    height: 100px;
    width: 100px;
    object-fit: cover;
    margin: auto;

    @include from-tablet {
      height: 120px;
      width: 150px;
    }

    &--hidden {
      display: none;
    }
  }

  &__image-mask {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), transparent 20px);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4),
      30%,
      transparent 100%
    );
  }
}
