@import '../../scss/base.module';

$dropdown-index: 4;
$dropdown-close-index: 5;

.dropdown-button {
  @include from-laptop {
    position: relative;
  }

  &__close {
    position: absolute;
    top: 32px;
    right: 28px;
    z-index: $dropdown-close-index;
  }

  &__chip {
    height: 40px;
    padding: $spacing-m $spacing-s;
  }

  &__button {
    white-space: nowrap;
    height: 100%;
    width: 100%;

    @include from-laptop {
      position: relative;
      padding-right: 9 * $spacing-xxs;
      &::before {
        position: absolute;
        color: #283947;
        border-style: solid;
        border-width: 1px 1px 0 0;
        content: '';
        display: inline-block;
        height: 2 * $spacing-xxs;
        width: 2 * $spacing-xxs;
        right: 4 * $spacing-xxs;
        top: 3 * $spacing-xxs;
        transform: rotate(135deg);
      }
    }

    &--open {
      &::before {
        top: 4 * $spacing-xxs;
        transform: rotate(-45deg);
      }
    }

    &--selected {
      &::before {
        border-color: $dark;
      }
    }
  }

  &__dropdown {
    position: absolute;
    background-color: white;
    min-width: 400px;
    box-shadow: 0 2px 10px 0 $light;
    z-index: $dropdown-index;
    top: 66px;
    left: 16px;

    @include from-laptop {
      top: 55px;
      left: unset;
    }

    &--right-to-left {
      top: 50px;
      right: 0;
    }
  }
}

.dropdown-button > button.dropdown-button__button--selected {
  border-color: $dark;
}
