@import '../../scss/base.module';

.app-banner {
  display: flex;
  background-color: $interaction;
  padding: $spacing-s $spacing-xs;
  line-height: 0;

  @include from-tablet {
    display: none;
  }
}

.close {
  display: inline-flex;
  align-items: center;
  fill: white !important; // prevents overrides from <IconButton /> styles
  padding: 0 $spacing-s 0 $spacing-xs !important;

  @include from-tablet {
    padding: 0 $spacing-xs 0 0 !important;
  }
}

.anchor {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: $spacing-s;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $spacing-xs;
}

.title {
  @include font-bold;
  @include font-s;

  color: white;
}

.text-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.rating {
  line-height: 1;
}

.cta {
  @include font-bold;

  margin-right: $spacing-s;
  padding: $spacing-s;

  @include from-tablet {
    margin-right: $spacing-m;
    padding: $spacing-xs;
  }
}
