@import '../../scss/base.module';

.gallery-arrow {
  @include is-animated;

  &:hover {
    fill-opacity: 1;
  }

  &--is-rotated {
    transform: rotate(180deg);
  }
}
