@import '../../scss/base.module';

.favourite {
  @include is-animated;

  padding: 0;
  border: none;
  cursor: pointer;
  pointer-events: all;
  background: none;
  &:focus {
    outline: none;
  }
}
