@import '../../scss/base.module';

.slider-figure {
  margin: 0;
  position: relative;
  transition: 1s;
  width: 100%;
  height: auto;

  &__wrapper {
    width: 100%;
  }

  &__image {
    min-width: 100%;
    max-height: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &--hidden {
    display: none;
  }

  figcaption {
    @include font-m;
    @include font-bold;

    bottom: $spacing-m;
    color: white;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
  }
}
