// Checkbox reimplementation to support two lines
// #TODO Once we move to the new search layout, apply this new checkbox
//       style to the whole website

.filters {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  @include from-laptop {
    height: auto;
  }

  &__header {
    padding:  $spacing-xs 0 0 0;
    display: flex;
    justify-content: space-between;

    &--sticky {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
    }
  }

  &__title {
    padding: 22px 22px 0 0;

    &-text {
      @include font-bold;
      margin: 0;
      display: inline-block;
      font-size: 30px;
      color: $dark;
      &--results {
        @include font-bold;
        @include font-m;
        display: flex;
        flex-direction: column;
        color: $interaction;
      }
    }
    &-description {
      @include font-s;
    }
  }

  &__clear {
    @include font-xs;
    margin-left: $spacing-s;
    text-decoration: underline;
    font-weight: normal;
  }
}

// Filter group component
.filter-group {
  display: flex;
  flex-direction: column;
  margin: ($spacing-l * 0.5) 0;

  @include from-wide {
    flex-direction: row;
  }

  &--datepicker {
    align-items: flex-start;
  }

  &--price-range {
    position: relative;
    display: flex;
    align-items: flex-start;

    &-blocker {
      position: absolute;
      width: 110%;
      height: 100%;
      margin-left: -$spacing-xs;
      background-color: white;
      opacity: 0.8;
      z-index: 2;
    }
  }

  &--price-range .form-slider__from,
  &--price-range .form-slider__to {
    @include font-s;

    margin-bottom: $spacing-s * 0.5;
    padding-bottom: 0;
  }

  &--smaller &-left-side  {
    @include from-wide {
      width: 25%;
    }
  }

  &-left-side {
    margin-bottom: $spacing-xs;

    @include from-wide {
      align-items: center;
      display: flex;
      margin: $spacing-s 0;
      width: 33%;
    }

    &__label {
      @include font-bold;
      display: flex;
      align-items: center;

      @include from-tablet {
        @include font-xs;
        @include font-bold;

        color: $dark;
        text-transform: uppercase;
      }
    }
  }

  @include until-phablet {
    padding-top: $spacing-s;
  }

  @include from-tablet {
    margin-top: 24px;
  }

  @include from-wide {
    margin-top: 32px;
  }

  &-right-side {
    position: relative;
    width: 100%;
    @include from-wide {
      width: 66%;
    }
  }

  & .checkbox-with-label {
    margin: 0;
    padding: 6px 0;

    @include until-phablet {
      &:last-child {
        padding-bottom: 0;
      }
    }

    @include from-tablet {
      width: 100%;
      padding: ($spacing-s * 0.5) 0;

      &:last-child {
        padding: ($spacing-s * 0.5) 0;
      }
    }

    @include from-wide {
      padding: 6px 0;
    }

    &__label {
      display: block;
      padding-left: 32px;

      &:before {
        margin-left: -32px;
      }
    }
  }

  &--property-type-checkboxes {
    & .filter-group-left-side {
      align-items: flex-start;
    }
  }

  &--property-type-checkbox {
    @include from-wide {
      align-items: center;
    }
  }

  &--checkboxes {
    & .filter-group-left-side {
      align-items: flex-start;
    }

    & .filter-group-right-side {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      display: flex;
      width: 100%;

      & .checkbox-with-label {
        display: flex;
        align-items: center;
        width: 100%;

        @include from-tablet {
          vertical-align: top;
        }
        @include from-laptop {
          width: 50%;
        }
      }
    }
  }

  &--align-label-to-first-row {
    @include from-tablet {
      align-items: baseline;
    }
  }
}

.filter-group--no-top-space {
  margin-top: 0;
  padding-top: 0;
}
