@import '../../../scss/base';

.home-card-banner-image {
  position: relative;

  &--full-height {
    height: 100%;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}
