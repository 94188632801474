@import '../../../../scss/base';

.select-banner-front {
  width: 100%;
  &__content {
    margin-top: 10%;
    position: absolute;
    display: flex;
    top: $spacing-s;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__title {
    max-width: 80%;
    text-align: center;
    padding: $spacing-s $spacing-m;
    @include font-m;
    @include font-bold;
  }
  &__title--one-column-view {
    padding: $spacing-s 0;
    @include font-l;
    @include font-bold;
  }
  &__label {
    text-transform: uppercase;
    @include font-s;
  }
  &__button {
    background-color: white;
  }
}
