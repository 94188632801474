@import '../../../../scss/base';

.select-bullet {
  &__item {
    display:flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $spacing-xxs;
  }
  &__icon {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-color: #8143ff;
    border: 1.5px solid #8143ff;
    padding: 2px;
    margin-right: 2 * $spacing-xxs;
  }
  &__text {
    @include font-s;
  }
}
