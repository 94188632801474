@import '../../scss/base.module';

.rating {
  display: inline-block;
  position: relative;
  transition: all 200ms ease-out;
}

.rating-stars {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 200ms ease-out;
}
