@import '../../scss/base.module';

.chip {
  --chip-size: 34px;

  border-radius: 100px;
  padding: $spacing-xs $spacing-s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--chip-size);
  width: fit-content;
  box-sizing: border-box;
  background-color: var(--chip-bg-color);
  color: var(--chip-color);
  border: 1px solid;
  border-color: var(--chip-border-color);
  white-space: nowrap;

  &--inline {
    display: inline-flex;
  }

  &--shadowed {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  }

  &:focus {
    outline: none;
  }

  &__label {
    @include font-s;
    @include font-bold;

    &--spaced {
      margin-left: $spacing-xxs
    }
  }

  &--schema-filter {
    cursor: pointer;

    i {
      @include font-s;

      font-weight: 700;
    }

    --chip-bg-color: #{$white};
    --chip-color: #{interaction-dark};
    --chip-border-color: transparent;

    &--with-border {
      --chip-border-color: #{$medium};

      &:hover {
        --chip-border-color: #{$light};
      }
    }

    &--selected {
      --chip-bg-color: #{$secondary-blue-light};
      --chip-color: #{$interaction};
      --chip-border-color: #{$info};

      &:hover {
        --chip-border-color: transparent;
      }
    }

    &--activated {
      --chip-bg-color: #{$interaction};
      --chip-color: #{$info};
      --chip-border-color: #{$interaction};
    }

    &--overlay {
      --chip-bg-color: #{$overlay-black};
      --chip-color: #{$white};
      --chip-border-color: transparent;

      &:hover {
        --chip-border-color: transparent;
      }
    }
  }

  &--schema-choice {
    cursor: pointer;

    i {
      @include font-xs;

      font-weight: 700;
    }

    --chip-bg-color: #{$white};
    --chip-color: #{$dark};
    --chip-border-color: #{$dark};

    &--dark-mode {
      --chip-bg-color: #{$dark};
      --chip-color: #{$white};
      --chip-border-color: #{$white};
    }

    &--selected {
      --chip-bg-color: #{$info};
      --chip-color: #{$interaction};
      --chip-border-color: #{$info};
    }

    &--activated {
      --chip-bg-color: #{$interaction};
      --chip-color: #{$info};
      --chip-border-color: #{$interaction};
    }

    &--activated-dark {
      --chip-bg-color: #{$white};
      --chip-color: #{$dark};
      --chip-border-color: #{$white};
    }
  }

  &--schema-tag {
    --chip-bg-color: #{$tertiary};
    --chip-color: #{$dark};
    --chip-border-color: #{$tertiary};
    --chip-size: 28px;

    & > div {
      @include font-xs;
    }

    padding: 6px 10px;

    &--dark-mode {
      --chip-bg-color: #{$white};
      --chip-color: #{$dark};
      --chip-border-color: #{$white};
    }

    &--overlay {
      --chip-bg-color: #{$medium};
      --chip-color: #{$white};
      --chip-border-color: #{$medium};
    }
  }

  &--schema-blue {
    --chip-bg-color: #{$secondary-blue-light};
    --chip-color: #{$dark};
    --chip-border-color: #{$secondary-blue-light};
    --chip-size: 28px;

    & > div {
      @include font-xs;
    }

    padding: 6px 10px;

    &--dark-mode {
      --chip-bg-color: #{$white};
      --chip-color: #{$dark};
      --chip-border-color: #{$white};
    }

    &--overlay {
      --chip-bg-color: #{$medium};
      --chip-color: #{$white};
      --chip-border-color: #{$medium};
    }
  }

  &--schema-pink {
    --chip-bg-color: #{$secondary-pink-light};
    --chip-color: #{$dark};
    --chip-border-color: #{$secondary-pink-light};
    --chip-size: 28px;

    & > div {
      @include font-xs;
    }

    padding: 6px 10px;

    &--dark-mode {
      --chip-bg-color: #{$white};
      --chip-color: #{$dark};
      --chip-border-color: #{$white};
    }

    &--overlay {
      --chip-bg-color: #{$medium};
      --chip-color: #{$white};
      --chip-border-color: #{$medium};
    }
  }

}
