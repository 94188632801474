@import '../../scss/base';

.homecard-label {

  &__tooltip {
    margin-left: $spacing-l;
    margin-bottom: $spacing-xxs;
    min-width: 215px;
    padding: $spacing-xs $spacing-s;

    &::after {
      left: $spacing-xxl;
    }
  }
}
