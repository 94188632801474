@import '../../scss/base';

.alert-in-past-modal {
  padding: $spacing-m;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: $spacing-l;
    }
  }

  &__footer {
    padding-top: 0!important;
  }
}
