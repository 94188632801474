@import '@spotahome/marketplace-common/src/scss/base';

.switcher {
  display: flex;
  align-items: center;
  width: 80px;
  height: 44px;
  background-color: $info;
  border-radius: 38px;
  position: relative;
  z-index: 1;

  &:focus {
    outline: none;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    z-index: 3;
    pointer-events: none;
    padding: 4px 2px;
    box-sizing: border-box;
  }

  &__ball {
    background-color: white;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    cursor: pointer;
    transition: 300ms ease transform;
    margin-left: 6px;

    &--right {
      transform: translateX(100%);
      margin-left: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    flex-direction: column;

    svg {
      max-width: 18px;
    }

    &--left, &--right {
      svg {
        width: 14px;
      }

    }
    &--right {
      margin-left: 3px;
    }
    &--left {
      margin-left: 3px;
    }
  }
}
