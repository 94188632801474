@import '../../../../scss/base';

.book-securely-banner-front {
  width: 100%;
  &__label-wrapper {
    @include font-bold;
    position: absolute;
    left: $spacing-m;
    bottom: $spacing-s;
    right: $spacing-m;
    color: white;
  }
  &__title {
    padding: $spacing-s $spacing-m;
    @include font-m;
    @include font-bold;
  }
  &__title--one-column-view {
    padding: $spacing-s 0;
    @include font-l;
    @include font-bold;
  }
  &__label {
    text-transform: uppercase;
    @include font-s;
  }
}
