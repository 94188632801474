@import '@spotahome/marketplace-common/src/scss/base';

.pre-filters {
  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-m 0 $spacing-s;
    overflow-x: auto;

    & > * + * {
      padding-left: $spacing-xs; // So box-sizing in list-item works

       @include from-laptop {
        padding-left: $spacing-s;
       }
    }
  }

  &__list-item {
    flex: 1 1;
    height: 86px;
    box-sizing: content-box;

    &:first-child {
      padding-left: $spacing-m;
    }

    &:last-child {
      padding-right: $spacing-m;
    }

    @include from-tablet {
      height: 100%;
      box-sizing: border-box;
    }

    @include from-laptop {
      min-width: 150px;
      height: 100%;
      box-sizing: border-box;

      &:first-child {
        padding-left: $spacing-xl;
      }
      &:last-child {
        padding-right: $spacing-xl;
      }
    }
  }
}
