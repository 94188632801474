@import '../../../../scss/base';

.checkbox {
  display: block;
  position: relative;
  margin: $spacing-s $spacing-xs 0;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  margin-top: 14px;
  margin-left: 11px;
  height: 1px;
  width: 1px;
}

.checkbox__label {
  @include disable-selection;
  color: $medium;
  cursor: pointer;
  display: flex !important;

  &__title {
    @include font-bold;
    color: $dark;
  }
}

.checkbox__count {
  margin-left: $spacing-xs;
}

.checkbox__label:before {
  content: '';
  display: inline-block;
  width: $spacing-l;
  height: $spacing-l;
  background: url('/bundles/sahapp/images/ui/checkbox.svg');
  background-size: contain;
  vertical-align: bottom;
  margin-right: $spacing-s;
}

.checkbox__input:checked + .checkbox__label:before {
  content: '';
  display: inline-block;
  width: $spacing-l;
  height: $spacing-l;
  background: url('/bundles/sahapp/images/ui/checkbox-enabled-new.svg');
  background-size: contain;
  vertical-align: bottom;
}

.checkbox__input:checked + .checkbox__label {
  color: $interaction;
}
