@import '@spotahome/marketplace-common/src/scss/base';

.alert-creation-reminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: $spacing-l;

  &__text {
    max-width: 650px;
    text-align: center;
    margin: $spacing-m $spacing-m;
  }

  &__cta {
    button {
      span {
        @include font-bold;
      }
    }
  }
}
