@import '@spotahome/marketplace-common/src/scss/base';

.sort-by {
  display: flex;
  position: relative;
  margin-left: auto;
  min-width: auto;

  &:after,
  &:before {
    content: '';
    border-style: solid;
    border-width: $spacing-xs;
    pointer-events: none;
    width: 0;
    height: 0;
    position: absolute;
    top: calc(50% - #{$spacing-xs} / 2);
    border-color: #000 transparent transparent;
    right: 1px;
  }

  &:after {
    margin-top: -1px;
    border-color: #fff transparent transparent;
  }

  &__select {
    @include font-xs;
    @include font-bold;
    color: black;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    -webkit-appearance: unset;
    padding-right: 20px;
    padding-left: $spacing-xs;
    flex-grow: 1;

    &:focus {
      outline: none;
    }
  }
}
