@import '../../scss/base.module';

.seo-snippet {
  @include font-s;

  line-height: $spacing-l;
  width: 100%;
  margin: 0 auto;

  &--centered {
    max-width: 700px;
  }

  @include until-phablet {
    width: 80%;
    word-break: break-word;
  }

  & section {
    padding-bottom: $spacing-xl;
  }

  & h2 {
    @include font-bold;

    color: $ultra-dark;
    padding: $spacing-xl 0 0;
  }

  & h3 {
    @include font-m;
    @include font-bold;
    color: $dark;
    padding-bottom: $spacing-s;
  }

  & p {
    @include font-s;

    padding-bottom: $spacing-xs;
  }

  & ul {
    padding: $spacing-xs;
    list-style-position: inside;
  }

  & td:not(:first-child) {
    padding-left: $spacing-xxxl;
  }
}
