@import '../../scss/base.module';

.info-banner {
  position: relative;
  background-color: $secondary-blue-light;
  font-family: PoppinsMedium;
  font-size: $font-s;
  line-height: $line-height-xs;
  border-radius: 16px;
  padding: $spacing-m;
  display: flex;
  justify-content: space-between;

  &--info {
    background-color: $secondary-blue-light;
  }

  &--suggestion {
    background-color: $secondary-pink;
  }

  &--suggestion-light {
    background-color: $secondary-pink-light;
  }

  &--tertiary {
    background-color: $tertiary;
  }

  @include from-tablet {
    justify-content: space-between;
  }

  &__icon {
    margin-right: $spacing-s;
  }

  &__text {
    @include from-tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }
  }

  &__close {
    margin-left: $spacing-m;
  }

  &__close, &__icon {
    align-self: flex-start;

    &--center {
      @include from-tablet {
        align-self: center;
      }
    }
  }
}
