@import '../../scss/base.module';

.form-slider__from,
.form-slider__to {
  padding: $spacing-s 0 $spacing-l;
  padding-bottom: 8px;
}

.form-slider__from {
  display: inline-block;
  width: 50%;
}

.form-slider__to {
  display: inline-block;
  width: 50%;
  text-align: right;
}
