@import '../base';

$filters-footer-height: 90px;
$menu-header-height: $SPOTAHOME_HEADER_HEIGHT;
$exposed-filter-header-height: 65px;
$HEADER_WITH_EXPOSED_FILTERS: $menu-header-height + $exposed-filter-header-height;

// Used in Map page to hide seo results
.lock-scroll {
  @include from-tablet {
    width: 100%;
    position: fixed;
  }
}

.l-main-section {
  background-color: $white;
}

.wrapper-search {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
}

.search__sidebar {
  background-color: $light;
  position: relative;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  height: auto;

  @include from-tablet {
    height: auto;
  }

  @include from-laptop {
    max-width: 61.80%;
  }
}

.search__scroll {
  background-color: white;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;

  @include from-laptop {
    width: auto;
  }
}

.search-map {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: $SEARCH_MAP_INDEX;
  top: 0;
  bottom: 0;

  @include from-laptop {
    position: sticky;
    position: -webkit-sticky;
    width: auto;
    height: calc(100vh - #{$menu-header-height} - #{$exposed-filter-header-height});
    top: $menu-header-height + $exposed-filter-header-height;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    pointer-events: none;

    &>* {
      pointer-events: all;
    }

    @include from-laptop {
      position: relative;
    }
  }

  &__filters {
    background-color: white;

    @include from-laptop {
      background-color: transparent;
    }
  }

  &__places {
    padding: $spacing-m;
    padding-bottom: 0;

    &--extra-content {
      padding: $spacing-m;
    }

    @include from-laptop {
      padding: 0;
    }
  }

  &__refresh-button {
    border: 1px solid $light;
    box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.13);
    color: $dark;
    transition: margin-top 200ms ease, opacity 200ms ease;
    opacity: 1;
    margin-top: $spacing-l;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    &--hidden {
      opacity: 0;
      margin-top: -$spacing-m;
    }

    *+* {
      margin-left: $spacing-xs;
    }

    & svg {
      vertical-align: middle;
    }
  }

  &__refresh-button-rebranding {
    border: 1px solid $light;
    background-color: $white;
    margin-top: $spacing-l;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    &--hidden {
      opacity: 0;
      margin-top: -$spacing-m;
    }

    *+* {
      margin-left: $spacing-xs;
    }

    & svg {
      vertical-align: middle;
    }
  }
}

.layout-search {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: column;
  overflow: inherit;
  position: static;

  &--header-fixed {
    padding-top: $SPOTAHOME_HEADER_HEIGHT;
  }

  .search {
    &-results {
      display: flex;
      flex-direction: row;
    }

    &-info-banner {
      margin: $spacing-m $spacing-m $spacing-xs;

      @include from-tablet {
        margin: $spacing-m $spacing-xl $spacing-xs;
      }
    }

    &__filters-full {
      background-color: white;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 70px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      z-index: $SPOTAHOME_HEADER_INDEX; // in mobile we want them to be on top of everything as modal
      padding: 0 $spacing-xl $spacing-xl $spacing-xl;
      box-sizing: border-box;

      @include from-tablet {
        top: $SPOTAHOME_HEADER_HEIGHT;
        z-index: $SEARCH_FULL_FILTERS_BUTTONS_INDEX; // tablet and above, we want them below the header + exposed filters
      }

      @include from-laptop {
        position: fixed;
        top: $HEADER_WITH_EXPOSED_FILTERS;
        width: 600px;
        height: calc(100vh - #{$menu-header-height} - #{$exposed-filter-header-height} - #{$filters-footer-height});
      }

      @include from-desktop {
        width: 700px;
      }

      @include from-wide {
        width: 960px;
      }
    }

    &-listing {
      position: relative;
      background-color: white;
      padding: $spacing-m $spacing-m;

      @include from-tablet {
        padding: $spacing-m $spacing-xl $spacing-xl;
      }

      &__total-results {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 0 $spacing-m;
      }
    }

    &__view-switcher {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 9;
      padding: 0 $spacing-m $spacing-m $spacing-m;
    }
  }

  #themap {
    width: 100%;
    height: calc(100% - 130px);
    bottom: 0;
    left: 0;
    position: absolute !important; // NOTE: necessary to override the GMaps library styles

    @include from-tablet {
      height: 100%;
    }
  }

  .spinner-loading-map {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
  }
}

// QuickAlertsSearch experiment mobile styles
.quick-alert-mobile__switcher {
  @include from-laptop {
    display: none;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  padding: 0 $spacing-m $spacing-m;
}

.quick-alert-mobile__floating {
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-xxs;
  overflow: hidden;
  border-radius: 22px;

  & > button+button {
    border-left: 1px solid $light;
  }
}

.wrapper-header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  height: $HEADER_WITH_EXPOSED_FILTERS;
  z-index: $SPOTAHOME_HEADER_INDEX;

  &--hidden {
    display: none;
  }
}
