@import '../../scss/base.module';

.label-item {
  @include font-bold;

  backface-visibility: hidden;
  display: flex;
  align-items: center;
  color: white;
  line-height: 1;
  margin-right: $spacing-xxs;
  max-height: $spacing-s;
  text-transform: uppercase;
  border-radius: 40px;
  box-sizing: content-box;
  z-index: $tooltip-z-index;
  background-color: white;

  &:last-child {
    margin-right: 0;
  }

  &__text {
    display: block;
    flex-grow: 1;
    text-transform: uppercase;
  }

  &__icon {
    margin-right: $spacing-xxs;
    & > svg {
      display: block;
    }
  }

  &--xxxs {
    font-size: $font-xxxs;
    padding: $spacing-xxs;
  }

  &--xxs {
    font-size: $font-xxs;
    padding: $spacing-xs;
  }

  &--xs {
    font-size: $font-xs;
    padding: $spacing-s;
  }
}
