@import '../../../../scss/base';

.select-banner-back {
  &__container {
    align-self: flex-start;
    margin: 0;
    width: 100%;
    padding: 8 * $spacing-xxs;
  }
  &__message {
    @include font-s;
    padding-left: $spacing-xxs;
    margin-bottom: $spacing-s;
  }
  &__back-icon {
    position: absolute;
    bottom: 2 * $spacing-xxs;
    right: 2 * $spacing-xxs;
  }
}
