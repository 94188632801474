@import '@spotahome/marketplace-common/src/scss/base';

.generic-banner {
  &__container {
    border-radius: $spacing-xxs;
    padding: $spacing-m;
    position: relative;
    margin-bottom: $spacing-xs;

    @include from-laptop {
      margin-top: - $spacing-xs;
      margin-bottom: $spacing-m;
    }

    @include from-desktop {
      margin-top: - $spacing-s;
      margin-bottom: $spacing-m;
    }

    &--blue-cerulean {
      background-color: #bad9f5;
    }
    &--blue-indigo {
      background-color: #c2c7e8;
    }
  }

  &__content {
    margin-right: $spacing-m;
    font-size: 0;
  }

  div &__label {
    display: inline-block;
    padding: 2px $spacing-xs;
    margin-bottom: 2px;
  }

  &__content-text {
    @include font-bold;

    &--blue-cerulean {
      color: $interaction;
    }
    &--blue-indigo {
      color: $dark;
    }
  }

  &__close-button {
    &--blue-cerulean {
      fill: $interaction;
    }
    &--blue-indigo {
      fill: $dark;
    }
    font-size: $font-l;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    transform: translateY(140%);
    position:absolute;
    top: 0;
    right: $spacing-m;
  }
}
