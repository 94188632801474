@import '../base';

.booknow--spinner-loader {
  background: white;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.booknow--spinner-loader__visible {
    display: flex;
    z-index: 2;
  }
}

@keyframes booknow-card--mobile-shortcut {
  from {
    transform: translate(0, 200px);
  }

  to {
    transform: translate(0, 0);
  }
}

.question-badge {
  display: inline-block;
  line-height: 1.4;
  cursor: pointer;
}

.rc-tooltip {
  @include until-phablet {
    position: fixed;
    top: $spacing-xs;
  }

  &-inner {
    box-sizing: border-box;
    max-width: 440px;
    background-color: $interaction;
    min-height: 0;

    @include until-phablet {
      max-width: initial;
      width: initial;
      margin: 0 1em;
    }
  }

  &-arrow {
    border-top-color: $interaction !important;
    border-bottom-color: $interaction !important;
  }
}

.tooltyp-content {
  &__list {
    list-style: none;
    padding: $spacing-s;

    @include until-phablet {
      padding: $spacing-xxs;
    }

    > li {
      @include font-s;
      @include font-bold;

      @include until-phablet {
        @include font-xs;
      }

      &:not(:last-child) {
        margin-bottom: $spacing-xxs;
      }
    }
  }
}

.info__icon {
  margin-left: $spacing-xs;
  cursor: pointer;
}

.pay-nothing-now {
  @include font-bold;
  @include font-xs;

  color: $medium;
  margin-top: $spacing-xs;
  text-align: center;
  display: none;

  @include from-laptop {
    display: block;
  }
}

.not-checked-block {
  margin-top: $spacing-m;
}

.book-now {
  &__wrapper {
    margin-bottom: $spacing-xs;
  }
}
