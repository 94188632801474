@import '@spotahome/marketplace-common/src/scss/base';

.filters-exposed {
  display: flex;
  background-color: white;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: $EXPOSED_FILTERS_INDEX;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 0;
  padding: $spacing-s $spacing-m $spacing-xs $spacing-m;
  align-items: center;

  & > * + * {
    margin-left: $spacing-s;
  }

  @include from-tablet {
    border-top: 1px solid $light;
    box-shadow: 0px 2px 6px #ccd6dd60;
    overflow-x: visible;
    overflow-y: visible;
  }

  @include from-laptop {
    padding: $spacing-s $spacing-m $spacing-s $spacing-xl;
  }

  &__content {
    padding: $spacing-m $spacing-l;
    padding-bottom: $spacing-xl * 4;

    @include from-tablet {
      padding-bottom: $spacing-m;
    }
  }

  &__filter-button {
    white-space: nowrap;
    position: relative;
    height: 40px;
    padding: $spacing-m $spacing-s;

    @include from-laptop {
      flex: 0;
    }

    & > span {
      @include font-bold;
      @include font-xs;
      border-radius: 50%;
      background-color: $interaction;
      color: $secondary-blue;
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: $spacing-xs;
    }
  }

  &__date-filter-dropdown {
    min-height: 400px;
  }

  &__save-search-button {
    @include font-bold;
    white-space: nowrap;
    position: relative;
    border-color: $light;
    height: 40px;
    padding: 14px 20px;
  }

  &__toggle-map {
    flex: 1;
    text-align: end;

    & > button {
      display: inline
    }
  }
}
