@import '../../../../scss/base';

.long-term-banner {
  color: white;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $spacing-xl $spacing-m $spacing-m;
  justify-content: space-between;

  &__title {
    @include font-xxxl;
    @include font-bold;
    color: white;
    margin-bottom: $spacing-xs;
    text-shadow: 1px 1px 3px black
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__discover-button {
    @include font-bold;

    width: 70%;
    color: $medium;
    background: white;

    &:hover {
      color: $medium;

    }
  }

  &__hide-button {
    position: absolute;
    top: $spacing-m;
    right: $spacing-m;
  }
}
