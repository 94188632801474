@import '../base';

.loading-icon__text {
  @include visual-hide;
}

.loading-icon {
  display: inline-block;
  width: 96px;
  height: 96px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;

  &.loading-icon-active {
    position: absolute;
    height: 100%;
    width: 100%;
    background: red;
    display: block;
    margin-left: 400px;
  }

  &.loading-icon--small {
    height: $spacing-l;
    width: $spacing-l;
  }
}

.loading-icon:before,
.loading-icon:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: $spacing-l;
  background: $dark;
  height: $spacing-l;
  width: $spacing-l;
  opacity: 0.4;
  animation: loading-bounce 2s infinite ease-in;
  top: $spacing-xxl - $spacing-s;
  left: $spacing-xxl - $spacing-s;
}

.loading-icon--small.loading-icon:before,
.loading-icon--small.loading-icon:after {
  height: $spacing-m;
  left: 3px;
  top: 3px;
  width: $spacing-m;
}

.loading-icon:after {
  animation-delay: 1s;
}

@keyframes loading-bounce {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }

  15% {
    transform: scale(0.8);
    opacity: 0.4;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}
