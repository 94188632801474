@import '@spotahome/ui-library/lib/scss/init.module';
@import 'rc-tooltip/assets/bootstrap.css';
@import '@spotahome/toast-grid/scss/grid';

// Base styles
@import './base';
@import './animations';
@import './layout/search-layout';

//Components
@import '../LEGACY/javascripts/ui/form/checkbox';

// Modules
@import './module/button';
@import './module/home-card';
@import './module/loading-icon';
@import './module/room';
@import './module/booknow-card';
@import './module/places-autocomplete';
@import './module/newsearch-filter-group';

@import './vendor/nouislider';

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  background: white;
  margin: 0;
  @include font-m;
  -moz-font-feature-settings: 'tnum' 1;
  -ms-font-feature-settings: 'tnum' 1;
  -o-font-feature-settings: 'tnum' 1;
  -webkit-font-feature-settings: 'tnum' 1;
  font-feature-settings: 'tnum' 1;
}

html,
body {
  @include font-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin-block-start: 0;
  margin-block-start: 0;
}

h1 {
  @include font-xxl;
}

h2 {
  @include font-l;
}

h3 {
  @include font-l;
  color: $light;
}

p {
  @include font-m;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

ul,
li,
ol {
  margin: 0;
}

strong,
b {
  font-family: $font-bold;
}

a {
  color: $interaction;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $interaction-dark;
  }
}

img {
  max-width: 100%;
}

input,
optgroup,
select,
textarea {
  font: inherit;
}

[hidden] {
  display: none;
}

.widget-container {
  z-index: 9999 !important;
}
