@import '../../scss/base.module';

@mixin disable-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-group-toggle {
  display: table;
  background: none;
  margin: $spacing-l 0;
  width: 100%;

  div:first-of-type label {
    border-radius: 4px 0 0 4px;
  }

  div:last-of-type label {
    border-radius: 0 4px 4px 0;
  }
}

.form-simple-toggle {
  display: table-cell;

  &__input {
    display: none;

    &:checked + .form-simple-toggle__label {
      color: $interaction;
      border: 1px solid $interaction;
      animation: toggle-in-transparent 0.4s ease-out;
    }
  }

  &__label {
    @include disable-selection;
    border: 1px solid $light;
    background: white;
    color: $medium;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    animation: toggle-out-transparent 0.4s ease-out;
    line-height: 50px;
    border-radius: 0;
    width: 100%;
    min-width: 30px;
    text-align: center;
    box-sizing: border-box;
  }
}
