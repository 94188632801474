@import '../../scss/base';

.applied-filters-modal {
  padding: $spacing-l;

  &__header {
    @include font-bold;
    font-size: $font-xl;
    color: $dark;
    text-align: center;
  }

  &__close-button {
    display: block;
    margin-left: auto;
  }

  &__icon {
    display: flex;
    justify-content: center;
    margin-top: $spacing-xxs;
    margin-bottom: $spacing-l;
  }

  &__filters {
    margin-top: $spacing-xxs;
    margin-bottom: $spacing-xs;
  }

  &__filter-value {
    @include font-bold;
    color: $dark;
  }

  &__cta {
    @include font-bold;
    display: block;
    margin-top: $spacing-l;
  }
}
