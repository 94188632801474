$noUi-track-height: 5px;
$noUi-handle-size: 28px;
$noUi-handle-vertical-offset: 2px;

.noUi-target,
.noUi-target * {
  @include disable-selection;

  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  height: $noUi-track-height;
  direction: ltr;
  border-radius: 4px;
  background-color: $light;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-origin {
  position: absolute;
  height: $noUi-track-height;
  background-color: $light;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.noUi-connect {
  position: absolute;
  height: $noUi-track-height;
  background: #657786;
  -webkit-transition: background 250ms;
  transition: background 250ms;
}

.noUi-handle {
  width: $noUi-handle-size;
  height: $noUi-handle-size;
  top: -($noUi-handle-size * 0.5) + $noUi-handle-vertical-offset;
  left: -($noUi-handle-size * 0.5) + $noUi-handle-vertical-offset;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 6px 0 rgba(20, 30, 39, 0.19);
  border-radius: 32px;
  background: #FFF;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  padding: 0 6px;
}

.noUi-vertical {
  width: 18px;

  .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;

    &:after {
      top: 17px;
    }
  }

  .noUi-dragable {
    cursor: n-resize;
  }
}

.noUi-background {
  background: $light;
}

.noUi-dragable {
  cursor: w-resize;
}

.noUi-active {
  background: $primary-light;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
