@import '../../../scss/base.module';

$spacing: $spacing-m;
$fav-icon-width: 32px;

.home-card {
  &__image-container {
    position: relative;
    line-height: 0;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @include from-laptop {
      border-radius: 16px;
    }
  }

  &__image {
    min-height: 100%;
    width: 100%;
    transition: transform 0.5s cubic-bezier(0, 0.84, 0, 0.05);

    &--hidden {
      display: none;
    }
  }

  &__image-mask {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), transparent 60px);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &__image-booked {
    @include font-bold;
    @include font-xs;

    position: absolute;
    height: 32px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $secondary;
    color: $dark;
    text-transform: uppercase;
    z-index: 2;
  }

  &__rating-container {
    position: absolute;
    left: $spacing;
    bottom: $spacing;
    z-index: 3;
    backface-visibility: hidden;
  }

  &__upper-elements {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    padding: $spacing-s;
  }

  &__favourite-container {
    height: 24px;
    right: $spacing;
    pointer-events: none;
  }

  &__discount--wrapper {
    min-width: 100px;
    max-width: 150px;
    position: absolute;
    right: 0;
    bottom: $spacing-xl;
    z-index: 3;

    &--hasTimer {
      bottom: 0;
    }
  }
}
