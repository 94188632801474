@import '../../../../scss/base.module';

.homecard-description {
  @include font-m;

  line-height: 1.4;
  background-color: white;

  border: 1px solid $light;

  @include from-desktop {
    border-radius: 16px;
  }

  display: flex;
  flex-flow: column;
  height: 100%;

  &__content {
    padding: $spacing-l $spacing-m $spacing-l $spacing-m;
    flex: 1;
    overflow: hidden;

    div + div {
      margin-top: $spacing-xs;
    }

    &__label {
      margin-right: $spacing-xxs;
    }
  }

  &__icons {
    i + i {
      margin-left: $spacing-xs;
    }
  }

  &__description {
    margin-top: $spacing-s;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    &--reduced {
      -webkit-line-clamp: 5;
    }
  }

  &__footer-separator {
    margin: 0;
    border: 1px solid #C7C7C7;
  }

  &__content-separator {
    border: 1px solid #EBEBEB;
  }

  &__footer {
    display:flex;
    justify-content: space-between;
    padding: $spacing-m;
  }
}
