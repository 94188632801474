@import '@spotahome/marketplace-common/src/scss/base';

.filter-header {
  @include font-bold;

  background: white;
  box-sizing: border-box;
  display: flex;
  padding: $spacing-m;
  border-top: 1px solid #f7f5f5;
  border-bottom: $spacing-xxs solid $primary;
  transition: opacity 300ms ease-in;
  position: sticky;
  top: 0;
  z-index: 7;
  width: 100%;

  *+* {
    margin-left: 0px;
  }

  @include until-tablet {
    flex-wrap: wrap-reverse;
    justify-content: space-between;
  }

  @include from-tablet {
    padding: 3 * $spacing-xs;
  }

  @include from-laptop {
    padding: (3 * $spacing-xs) (4 * $spacing-xs);

    *+* {
      margin-left: $spacing-m;
    }
  }

  &--expanded {
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #f7f5f5;
    background: white;
    z-index: $SEARCH_FULL_FILTERS_BUTTONS_INDEX;
    position: fixed;
    top: unset;
    bottom: 0;
    left: 0;

    @include from-laptop {
      position: absolute;
    }

    &--new-layout {

      @include from-laptop {
        width: 600px;
        position: fixed;
      }

      @include from-desktop {
        width: 700px;
      }

      @include from-wide {
        width: 960px;
      }
    }

    @include until-tablet {
      min-width: 100%;

      *+* {
        margin-left: $spacing-xs;
      }
    }
  }

  &__city {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
  }

  &__button-container {
    display: flex;

    *+* {
      margin-left: $spacing-xs;
    }

    &--filters-collapsed {
      @include until-tablet {
        min-width: 100%;

        *+* {
          flex: 1;
        }
      }
    }
  }

  &__results-button {
    &--filters-collapsed {
      @include until-tablet {
        justify-content: center;
        min-width: 49%;
      }
    }
  }

  &--rearranged {
    @include until-phablet {
      flex-wrap: nowrap;

      >* {
        margin-top: 0;
        min-width: unset;
        flex: 1;
      }

      *+* {
        margin-left: $spacing-xs;
      }

      .filter-header__results-button {
        width: 100%;
      }
    }
  }
}
