@import '@spotahome/marketplace-common/src/scss/base';

.availability-filter {
  --arrow-base-size: #{$spacing-xxl};
  --arrow-color: #{$light};

  display: flex;
  flex-direction: column;

  &__title {
    @include font-s;
    @include font-bold;
    margin-top: $spacing-m;
    margin-bottom: $spacing-xs;
  }

  &__subtitle {
    @include font-xs;
    @include font-bold;
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xs;
  }

  &__button-container {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: $spacing-s;
      margin-left: 0;
    }

    &--flexible {
      margin-bottom: $spacing-l;
    }

    @include from-tablet {
      flex-direction: row;

      & > * + * {
        margin-top: 0;
        margin-left: $spacing-s;
      }
    }
  }

  &__button-text {
    @include font-s;
  }

  &__arrow {
    flex: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: var(--arrow-base-size);
    position: relative;

    &:after, &:before {
      content: '';
      display: block;
    }

    &:before {
      height: 1px;
      background-color: var(--arrow-color);
      width: calc(var(--arrow-base-size) * 0.9);
    }

    &:after {
      position: absolute;
      right: 0;
      width: 0;
      height: 0;
      border-top: calc(var(--arrow-base-size) / 10) solid transparent;
      border-left: calc(var(--arrow-base-size) / 5) solid var(--arrow-color);
      border-bottom: calc(var(--arrow-base-size) / 10) solid transparent;
    }
  }
}
