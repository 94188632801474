.room {
  padding: $spacing-l 0;
  border-top: 1px solid $light;

  @include from-laptop {
    padding: $spacing-xl 0;
  }
}

.minimum-stay,
.minimum-stay > span {
  @include font-s;
  display: block;
  text-align: center;
}
