@import '../../scss/base';

.internal-linking {
  @include font-s;
  margin-bottom: 1em;
  margin-top: 2em;
  display: grid;
  width: 100%;
  padding: $spacing-xl;

  &__title {
    @include font-s;
    @include font-bold;
    color: black;
    margin-bottom: $spacing-xxs;
  }

  &__links {
    margin: $spacing-xxs 0;
    grid-gap: 8px 12px;
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));

    @include from-tablet {
      grid-template-columns: repeat(4,minmax(0,1fr));
    }
  }
}
