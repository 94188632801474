@import '../../../scss/base';

.home-card-banner {
  @include font-m;

  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  perspective: 2000px;
  -webkit-perspective: 2000px;

  &--flipped {
    > .home-card-banner__wrapper {
      transform: rotateY(180deg);

      > .home-card-banner__side--front {
        position: absolute;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-in, visibility 0.35s linear;
      }
      > .home-card-banner__side--back {
        position: relative;
      }
    }
  }
  &--multi-column-view {
    height: 100%;
    > .home-card-banner__wrapper {
      height: 100%;
      > .home-card-banner__side {
        height: 100%;
      }
    }
  }
  &__wrapper {
    height: inherit;

    transform-style: preserve-3d;
    transition: transform 0.5s linear;

    &--select {
      box-shadow: 0px 2px 0px #8143ff, 0 2px 2px rgba(0, 0, 0, 0.22);
      &:hover {
        box-shadow: 0px 0px 0px 2px #8143ff, 0 4px 16px rgba(0, 0, 0, 0.22);
      }
      &:focus {
        outline: none;
      }
    }

    &--default {
      box-shadow: 0px 2px 0px $primary-dark, 0 2px 2px rgba(0, 0, 0, 0.22);
      &:hover {
        box-shadow: 0px 0px 0px 2px $primary-dark,
          0 4px 16px rgba(0, 0, 0, 0.22);
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__side {
    overflow: hidden;
    width: 100%;
    background-color: white;
    backface-visibility: hidden;
    transition: box-shadow $time ease-out;
  }
  &__side--front {
    position: relative;
    width: 100%;
  }
  &__side--back {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
