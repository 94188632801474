.home-card {
  position: relative;
  height: 100%;

  &--selected {
    box-shadow: 0 0 8px 4px #a7a7a747;
    border-radius: 16px;
    overflow: hidden;
  }
}
