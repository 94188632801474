@import '../../../../scss/base.module.scss';

.filters-exposed-info-box {
  margin: 0 #{-$spacing-l} $spacing-s;

  & > * {
    padding-left: $spacing-l;
    padding-right: $spacing-l;
  }
}

.filters-exposed-title {
  margin-bottom: $spacing-s;

  &__title {
    @include font-bold;
    font-size: 30px;
    color: #3c3c3c;
    margin-bottom: $spacing-xs;
    &--results {
      @include font-s;
      margin-top: $spacing-s;
      padding: 0;
    }
  }

  &__subtitle {
    display: flex;
    @include font-s;
  }
}

.filters-exposed-button {
  padding: $spacing-l;
  @include from-tablet {
    padding: 0 $spacing-l $spacing-m;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    @include from-tablet {
      max-width: 200px;
    }
  }

  @include until-tablet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  background-color: white;

  @include font-bold;
  span {
    @include font-s;
  }
}

.exposed-filter {
  white-space: nowrap;
  position: relative;

  @include from-laptop {
    flex: 0;
  }

  & + & {
    margin-left: $spacing-s;
  }

  &__content {
    padding: $spacing-m $spacing-l;
    padding-bottom: $spacing-xl * 4;

    @include from-tablet {
      padding-bottom: $spacing-m;
    }
  }
}

