@import '@spotahome/marketplace-common/src/scss/base';
.l-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__item {
    width: 100%;
    padding: 0 $spacing-s;
    box-sizing: border-box;
    margin-bottom: 40px;

    @include from-tablet {
      width: 50%;
    }

    @include from-desktop {
      width: 33.3%;
    }

    @include from-ultrawide {
      width: 25%;
    }
  }

  &__placeholder {
    width: 100%;
    margin-bottom: $spacing-xl;
  }

  &__toast-icon {
    margin-top: 4px; //small margin to fix default toast icon position.
  }

  &__vp-banner {
    margin: 0 $spacing-s 40px; // same spacing as any homecard
    width: 100%;
  }
}
