@import '../../../../scss/base.module';

.horizontal-homecard-content {
  @include font-xs;

  padding: $spacing-s $spacing-m;
  background-color: white;
  flex: 1;
  min-width: 0;

  @include from-tablet {
    @include font-s;
  }

  &-header {
    @include font-bold;
    color: $interaction-dark;
    &__available-from {
      font-style: italic;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &-body {
    color: $medium;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    &__price {
      color: $interaction-dark;

      @include from-tablet {
        @include font-xs;
      }

      &--hidden {
        @include font-bold;
        color: $interaction-dark;
      }
    }
  }
}
