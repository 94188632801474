@import '../../../../scss/base.module.scss';

.exposed-filter-checkbox-section {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-xs;

  &__title {
    padding-top: $spacing-s;
    @include font-m;
    @include font-bold;
    color: $dark;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
  }

  &__filter {
    display: flex;
    width: 50%;
    white-space: nowrap;
  }

  &__tooltip {
    margin: 0 $spacing-xxs;
    cursor: pointer;

    span {
      padding: $spacing-s;
      // overrides UI Library displacement
      left: -23px !important;
      bottom: 35px !important;
      white-space: pre-wrap;
      height: auto;
      &:after {
        border-top-color: $interaction;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__checkbox {
    margin: $spacing-s $spacing-xs 0;
  }
}
