@import '../../scss/base';

.homecard-map {
  width: calc(100% - #{$spacing-m * 2});
  background-color: $light;
  margin: auto 0 80px 0;
  align-self: center;
  display: flex;

  @include from-tablet {
    margin: $spacing-m 0 0 0;
    max-width: 600px;
    position: absolute;
    bottom: 72px;
  }

  @include from-laptop {
    display: none;
  }

  &__placeholder {
    background-color: white;
    width: 100%;
    height: 100px;
    @include from-tablet {
      height: 120px;
    }

    svg {
      height: 100px;
      @include from-tablet {
        height: 120px;
      }
    }
  }

  &--variant {
    max-width: 440px;
    margin: auto auto 0;
    padding: $spacing-xxl $spacing-m $spacing-l;
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
  }

  &__close {
    fill: #1C1B1F;
    position: absolute;
    right: $spacing-l;
    top: $spacing-m;
  }
}