@import '../../scss/base.module';

.alert-box {
  @include font-s;

  display: flex;
  align-items: center;
  color: $interaction-dark;
  padding: $spacing-m;

  &--info {
    background-color: $secondary-blue-light;
  }

  &--suggestion {
    background-color: $secondary-pink;
  }

  &--suggestion-light {
    background-color: $secondary-pink-light;
  }

  &--tertiary {
    background-color: $tertiary;
  }

  &__icon {
    padding-right: $spacing-s;
    align-self: center;
    display: none;

    & > svg {
      display: block;
    }

    @include from-phablet {
      &--web {
        display: inline-block;

        &-top {
          align-self: flex-start;
        }
      }

      &--close {
        display: inline-block;
        margin-left: auto;

        &-top {
          align-self: flex-start;
        }
      }
    }

    @include only-mobile {
      &--mobile {
        display: inline-block;
      }
    }
  }

  &__title {
    @include font-bold;

    display: flex;

    &--inline {
      margin-right: $spacing-xs;
    }
  }

  &--rounded-corners {
    border-radius: $border-radius * 4;
  }

  &__content {
    &--inline {
      display: flex;
      align-items: flex-start;
    }
  }
}
