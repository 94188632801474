@import '../../../../scss/base.module';

$time: 120ms;
$easing: ease-in-out;

.horizontal-home-card {
  @include font-s;

  border-radius: $spacing-xxs;
  box-shadow: 0px 2px 0px $interaction, 0 2px 2px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;
  transition: box-shadow $time $easing;
  flex-direction: row;
  height: 100px;

  @include from-tablet {
    @include font-m;
    height: 120px;
  }

  &:hover {
    box-shadow: 0px 0px 0px 2px $interaction, 0 4px 16px rgba(0, 0, 0, 0.22);
  }

  &--shadowless {
    box-shadow: unset;
  }
}
