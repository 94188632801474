@import '../../scss/base.module';

.favorite-count {
  @include font-s;
  @include font-bold;
  background-color: $secondary-blue-light;
  display: flex;
  align-items: center;
  padding: $spacing-xxs $spacing-xs;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  pointer-events: all;

  &:focus {
    outline: none;
  }

  > * + * {
    margin-left: $spacing-xs;
  }
}
