@import '@spotahome/marketplace-common/src/scss/base';

.seo-wrapper {
  @include font-s;

  width: 100%;
  margin: 0 auto;

  &--centered {
    width: 80%;
  }

  @include until-phablet {
    width: 80%;
    word-break: break-word;
  }

  & section {
    padding-bottom: $spacing-xl;
  }

  & h2 {
    @include font-bold;

    color: $ultra-dark;
    padding: $spacing-xl 0 0;
  }

  & h3 {
    @include font-m;
    @include font-bold;
    color: $dark;
    padding-bottom: $spacing-s;
  }

  & p {
    @include font-s;

    padding-bottom: $spacing-xs;
  }

  & ul {
    padding: $spacing-xs;
    list-style-position: inside;
  }
}
