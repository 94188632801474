@import '../../scss/base.module';

.form-histogram {
  display: flex;
  align-items: flex-end;
}

.form-histogram__bar {
  background: $light;
  display: inline-block;
  vertical-align: bottom;
  border-left: 1px solid white;
  transition: background 2s cubic-bezier(0, 1.12, 0.58, 1), height 1s ease-out;

  &.form-histogram__bar--active {
    background: $secondary;
  }
}
