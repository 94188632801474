@import '../../scss/base.module';

.spotty-cat {
  $SPOTTY_CAT_BOTTOM_POSITION: 10%;

  &__option {
    flex-grow: 1;

    &-title {
      @include font-bold;
      @include font-s;

      margin: 0;
    }

    &-subtitle {
      @include font-xs;

      color: $interaction-dark;
      margin: 0;
      word-break: break-word;
    }
  }

  &__options {
    width: 90%;

    label + label {
      margin-top: $spacing-s;
      color: $interaction-dark;
    }

    @include from-tablet {
      width: 70%;
    }
  }

  &__image {
    position: fixed;
    display: block;
    cursor: pointer;
    transition: 0.4s ease all;
    z-index: 10;
    bottom: $SPOTTY_CAT_BOTTOM_POSITION;
    right: -80px;
    width: 140px;
    transform: rotate(-90deg);
    padding: $spacing-s;
    box-sizing: border-box;

    @include from-laptop {
      bottom: -58px;
      right: -10px;
      width: 120px;
      transform: unset;
    }

    &--hidden {
      right: -93px;

      @include from-laptop {
        bottom: -86px;
        right: -10px;
      }
    }

    &:hover {
      @include from-laptop {
        bottom: -50px;
      }
    }
  }

  &__notification {
    --size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 25px;
    background-color: #ea2121;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    width: var(--size);
    height: var(--size);
    z-index: 11;
    bottom: calc(#{$SPOTTY_CAT_BOTTOM_POSITION} + 94px);
    transform: rotate(-90deg);

    @include from-laptop {
      bottom: 44px;
      transform: unset;
    }

    &--hidden {
      display: none;
    }
  }

  &__title {
    @include font-title;
    @include font-xl;

    text-align: center;
    max-width: 300px;
    margin: $spacing-m auto;

    @include from-tablet {
      max-width: 340px;
    }
  }

  &__email {
    width: 100%;
    padding: 12px 0;
    margin-bottom: auto;
    text-align: center;

    @include from-tablet {
      display: flex;
      margin: 0 auto $spacing-s;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__marketing-opt-in {
    margin-bottom: $spacing-s;
  }

  &__autocompleter {
    border: 1px solid $light;
    border-radius: 4px;
    width: 300px;
  }

  &__success {
    text-align: center;
    padding-bottom: 56px;

    &-title {
      @include font-title;
      @include font-xxxl;

      @include from-tablet {
        @include font-xxxxxl;
      }
    }

    &-icon {
      margin-bottom: $spacing-l;
    }

    &-highlighted {
      @include font-bold;

      margin-bottom: $spacing-s;
    }

    &-image {
      width: 70%;

      @include from-tablet {
        width: 40%;
      }
    }
  }

  &__button {
    display: inline-block !important;
    min-width: 220px;
    padding: $spacing-m;
    margin: $spacing-xs;

    @include from-tablet {
      margin: $spacing-m;
    }

    &--whatsapp {
      display: inline-block !important;
      padding: $spacing-m $spacing-xl;
      margin-bottom: $spacing-xl;
      margin-top: $spacing-m;
    }
  }

  &__error {
    @include font-s;

    color: $danger;
  }

  &__terms-and-conditions {
    @include font-s;

    margin-bottom: $spacing-s;
  }
}
