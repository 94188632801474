@import '../../../../scss/base.module';

.homecard-content {
  @include font-s;

  line-height: 1.4;
  background-color: white;

  &--with-transparent-background {
    background-color: transparent;
  }

  padding: $spacing-s 0 $spacing-xxs 0;

  &__header {
    &:is(a) {
      cursor: pointer;

      .homecard-content__title {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    @include font-bold;
    color: $interaction-dark;
    cursor: default;
  }

  &__title {
    @include font-m;
    @include font-bold;

    color: $interaction-dark;
    margin-bottom: $spacing-xxs;
    margin-top: $spacing-xxs;
    line-height: $line-height-s;

    &--variant {
      @include font-s;
    }
  }

  &__available-from {
    @include font-xs;
    @include font-bold;

    display: inline-block;
    flex: 1;
    text-transform: uppercase;
    color: $interaction-dark;

    &--variant {
      @include font-xxs;
    }
  }

  &__squared-meters {
    @include font-xs;
    @include font-bold;

    display: inline-block;
    flex: 1;
    color: $interaction-dark;
  }

  &__rooms {
    min-width: 70px;
    color: $medium;
  }

  &__label-container {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-xxs;
  }

  &__type {
    @include font-xs;
    @include font-regular;

    display: inline-block;
    text-transform: uppercase;
    color: $interaction-dark;

    &--variant {
      @include font-xxs;
      @include font-bold;
    }
  }

  &__booked {
    @include font-xs;
    @include font-bold;

    text-transform: uppercase;
    color: $interaction-dark;
  }

  &__separator {
    vertical-align: middle;
    padding: 0 $spacing-xs;
    font-size: $spacing-xxs;
    color: $interaction-dark !important;
  }

  &__footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 2px;
    flex-direction: column;

    & > * + * {
      margin-top: $spacing-s;
    }
  }

  &__info {
    &--default {
      margin-top: 0px;
    }

    &--bottom {
      margin-top: $spacing-xs;
    }
  }

  &__price {
    color: $interaction-dark;
    white-space: nowrap;
  }

  &__price-container {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-xxs;
  }
}
