@import '../../scss/base.module';

.chip-dropdown {
  &__icon {
    svg {
      display: block;
      margin-left: $spacing-xs;
    }

    @include until-laptop {
      display: none;
      margin-left: 0;
    }
  }
}
