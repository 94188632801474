@import '@spotahome/marketplace-common/src/scss/base';

.remove-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-xs;

  Button {
    margin-right: $spacing-xs;
    margin-bottom: $spacing-xs;
  }
}
