@import '../scss/base.module.scss';

.hotspots-led {
  --base-size: 16px;
  --animation-duration: 1.5s;

  &:after, &:before {
    content: '';
    height: var(--base-size);
    width: var(--base-size);
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1;
    background-color: #C9E3F7; // temporal color
  }

  &:before {
    animation: led-out-in var(--animation-duration) linear infinite;
  }

  &:after {
    animation: led-out-in var(--animation-duration) linear calc(var(--animation-duration) / 2) infinite;
  }
}

@keyframes led-out-in {
  0% {
    height: calc(2 * var(--base-size));
    width: calc(2 * var(--base-size));
    top: calc(var(--base-size) / -2);
    right: calc(var(--base-size) / -2);
    opacity: .5;
  }
  25% {
    opacity: 0;
  }
  50% {
    height: var(--base-size);
    width: var(--base-size);
    top: 0;
    right: 0;
    opacity: 1;
  }
  100% {
    height: calc(2 * var(--base-size));
    width: calc(2 * var(--base-size));
    top: calc(var(--base-size) / -2);
    right: calc(var(--base-size) / -2);
    opacity: .5;
  }
}
