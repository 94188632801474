$time: 250ms;
$easing: ease-out;
$ease-bounce: cubic-bezier(0.18, 0.89, 0.32, 1.28);
$animation: ease-out;

a,
i,
.is-animated {
  transition: color $time $easing, opacity $time $easing,
    background-color $time $easing, box-shadow $time $easing;
}

svg .path {
  transition: fill $time $easing, opacity $time $easing;
}

@mixin animation-hover {
  transform: translate(0px, -4px);
}

@mixin animation-active {
  transform: translate(0px, 4px);
}

@keyframes pulse {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 0.5;
  }
}

@mixin animation($delay, $duration, $animation, $fill: both) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fill;
}
