@import '../../../../scss/base';

.home-card-banner-back {
  &__container {
    margin: 0;
    width: 100%;
  }
  &__wrapper {
    padding: $spacing-s $spacing-m;
    &--multi-column-view {
      text-align: center;
    }
  }
  &__top {
    display: flex;
    align-items: flex-start;
    @include font-bold;
    color: $interaction;
    padding-bottom: $spacing-m;
  }
  &__title {
    @include font-l;
    padding-left: $spacing-xxs;
  }
}
