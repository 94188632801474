@import '@spotahome/marketplace-common/src/scss/base';

.vp-checkbox {
  &__icon {
    &::before {
      font-size: $font-l;
    }
  }
}

.filter-item {
  &--with-chip {
    &>:first-child, &>:nth-child(3) {
      padding-top: calc($spacing-xxs / 2); // Custom spacing to match the extra height from the chip
    }
  }
}