@import './mixins/break-points.module';
@import './mixins/button-shadow.module';
@import './mixins/card-box.module';
@import './mixins/fonts.module';
@import './mixins/link.module';
@import './mixins/animations.module';
@import './mixins/fonts.module';
@import './mixins/seo-landings.modules.scss';

@mixin disable-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin max-container-homepage {
  @include from-laptop {
    width: 100%;
    max-width: $max-width + 64px;
    margin: 0 auto;
    padding: 0 $spacing-l;
  }
  @include from-wide {
    max-width: 1144px;
  }
}
