.button {
  @include font-bold;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  line-height: 58px;
  background: $primary;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  display: inline-block;
  position: relative;
  transition: background 0.1s ease-out, translate 0.1s ease-out,
    color 0.1s ease-out, border 0.1s ease-out;
  border: 1px solid $primary;

  &:hover,
  &:focus {
    background: $primary-light;
    border-color: $primary-light;
    color: white;
  }

  &:active {
    box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.2);
    transform: translate(0px, 2px);
  }

  &.button--secondary {
    border: 1px solid $primary;
    color: $primary;
    background: transparent;

    &:hover {
      border: 1px solid $primary-light;
      color: $primary-light;
    }

    &:active {
      box-shadow: none;
    }
  }

  &.button--secondary-fill {
    border: transparent;
    background: white;
    color: $primary;

    &:hover {
      opacity: 0.5;
    }
  }

  &.button--small {
    @include font-s;

    padding: $spacing-xs $spacing-s;
  }

  &.button--medium {
    @include font-bold;

    min-width: 150px;
    line-height: 48px;
  }

  &.button--wide {
    display: block;
    clear: both;
    text-align: center;
  }

  &.button--disabled {
    background: $light;
    border-color: $light;
    cursor: default;
    text-align: center;
    width: auto;

    &:active {
      box-shadow: none;
      transform: translate(0, 0);
    }
  }

  &.button--info {
    @include font-bold;

    background-color: white;
    border: 1px solid $interaction;
    color: $interaction;
    line-height: 48px;
  }
}

@keyframes favorites--selected {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.74, 1.74, 1.74);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.13, 1.13, 1.13);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.group-button--display-flex {
  .button:first-child {
    text-align: center;
    width: 50%;
    flex: 1 1 auto;
  }
}
