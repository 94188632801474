@import '../../../../scss/base.module.scss';

.price-range-helper {
  &__inputs {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    @include from-laptop {
      margin-bottom: 0px;
    }
  }

  &__input {
    position: relative;
    display: flex;
    width: 48%;
    flex-basis: 48%;
    align-items: center;

    ::placeholder {
      font-style: normal;
    }
  }

  &__error {
    display: block;
    width: 100%;
    color: red;
    margin-top: 8px;
    @include font-s;
    white-space: pre-line;
  }

  &__currency {
    $spacing: 11px;
    position: absolute;
    top: $spacing;
    right: $spacing;

    &--left {
      right: unset;
      left: $spacing;
    }
  }

  &__price {
    width: 100%;
    padding: 10px 24px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $light;

    &--has-error {
      color: red;
      border: 1px solid red;
    }
  }

  &__separator {
    margin: 0 $spacing-xs;
    width: 2%;
    flex-basis: 2%;
    text-align: center;
  }
}
